<template>
  <b-modal
    ref="modalRoomEdit"
    :visible="editRoomActive"
    @hidden="closeModal"
    size="lg"
    :busy="busy"
    :title="`Edit Room`"
    ok-title="Save Changes"
    centered
    body-class="pt-0"
    @ok="saveChanges"
  >
    <b-row>
      <b-col cols="12" md="6" lg="6">
        <b-form-group label="Room Name" label-for="room-name">
          <b-form-input required autofocus id="room-name" placeholder="" v-model="roomEditData.name" />
        </b-form-group>
      </b-col>

      <b-col cols="12" md="6" lg="6">
        <b-form-group label="Room Building" label-for="room-building">
          <v-select
            v-model="roomEditData.building"
            :reduce="(val) => val.value"
            :options="buildingOptions"
            :clearable="false"
            transition="smooth"
            input-id="room-building"
          />
        </b-form-group>
      </b-col>

      <b-col cols="12" md="6" lg="6">
        <b-form-group label="Room Floor" label-for="room-floor">
          <b-form-input id="room-floor" placeholder="Ex. B1" v-model="roomEditData.floor" />
        </b-form-group>
      </b-col>

      <b-col cols="12" md="6" lg="6">
        <b-form-group label="Room Number" label-for="room-number">
          <b-form-input id="room-number" placeholder="Ex. 405" v-model="roomEditData.number" />
        </b-form-group>
      </b-col>

      <b-col cols="12" md="6" lg="6">
        <b-form-group label="Room Capacity" label-for="room-capacity">
          <b-form-input id="room-capacity" placeholder="Ex. 100" v-model="roomEditData.capacity" />
        </b-form-group>
      </b-col>

      <b-col cols="12" md="6" lg="6">
        <b-form-group label="Room Directions" label-for="room-directions">
          <b-form-input id="room-directions" placeholder="Ex. Go down the hall and turn left" v-model="roomEditData.directions" />
        </b-form-group>
      </b-col>

      <b-col cols="12" md="6" lg="6">
        <b-form-group label="Room Avatar URL" label-for="room-avatar">
          <b-form-input id="room-avatar" placeholder="" v-model="roomEditData.avatar" />
        </b-form-group>
      </b-col>

      <b-col cols="12" md="6" lg="6">
        <b-form-group label="Room Picture URL" label-for="room-picture">
          <b-form-input id="room-picture" placeholder="" v-model="roomEditData.pictureUrl" />
        </b-form-group>
      </b-col>

      <b-col cols="12" md="6" lg="6">
        <b-form-group label="Status" label-for="room-status">
          <v-select
            v-model="roomEditData.status"
            :options="statusOptions"
            :reduce="(val) => val.value"
            :clearable="false"
            transition="smooth"
            input-id="room-status"
          />
        </b-form-group>
      </b-col>

      <b-col cols="12" md="6" lg="6">
        <b-form-group label="Room Type" label-for="room-type">
          <v-select
            v-model="roomEditData.type"
            :reduce="(val) => val.value"
            :options="typeOptions"
            :clearable="false"
            transition="smooth"
            input-id="room-type"
          />
        </b-form-group>
      </b-col>
    </b-row>
  </b-modal>
</template>

<script>
import { BCol, BFormGroup, BButton, BFormInput, BFormTextarea, BFormSelect, BFormSelectOption, BModal, BRow, VBModal } from 'bootstrap-vue';

import 'quill/dist/quill.snow.css';
import { quillEditor } from 'vue-quill-editor';
import { ref } from '@vue/composition-api/dist/vue-composition-api';
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';
import useRoom from '../useRoom';
import vSelect from 'vue-select';
import store from '@/store';
import states from '@/libs/data/states';
import _ from 'lodash';

export default {
  name: 'RoomEditModal',
  components: {
    BRow,
    BCol,
    BFormGroup,
    BButton,
    BFormInput,
    BFormTextarea,
    BFormSelect,
    BFormSelectOption,
    BModal,
    quillEditor,
    vSelect,
  },
  directives: { 'b-modal': VBModal },
  props: {
    editRoomActive: {
      type: Boolean,
      required: true,
      default: false,
    },
    roomData: {
      type: Object,
      default: undefined,
    },
  },
  data() {
    return {
      states,
      buildingOptions: [],
    };
  },
  watch: {
    '$props.editRoomActive'(val) {
      this.initModal();
    },
    '$props.roomData'(val) {
      this.initModal();
    },
  },
  created() {
    this.initModal();
  },
  methods: {
    initModal() {
      if (this.$props.roomData) {
        const { id, name, building, floor, number, capacity, directions, avatar, pictureUrl, type, status } = _.cloneDeep(
          this.$props.roomData,
        );

        this.roomEditData = {
          id,
          name,
          building,
          directions,
          capacity,
          number,
          floor,
          pictureUrl,
          avatar,
          type,
          status,
        };
      }
    },
    closeModal() {
      this.busy = false;
      this.$emit('update:edit-room-active', false);
    },
    validateForm() {
      if (
        !this.roomEditData.name ||
        !this.roomEditData.type ||
        !this.roomEditData.building ||
        !this.roomEditData.floor ||
        !this.roomEditData.capacity
      ) {
        return false;
      }
      return true;
    },
    saveChanges(event) {
      this.busy = true;
      event.preventDefault();

      if (this.validateForm() !== true) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Missing Required Fields',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
            text: 'Please fill out all required fields.',
          },
        });
        this.busy = false;
        return;
      }

      const pl = { ...this.roomEditData };

      console.log(pl);

      store
        .dispatch('siteStoreModule/updateRoom', { roomId: pl.id, roomData: pl })
        .then((response) => {
          // this.$emit('update:room-data', response.data);

          this.busy = false;
          this.$emit('refetch-data');
          this.closeModal();

          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Room Edited!',
              icon: 'EditIcon',
              variant: 'success',
            },
          });
        })
        .catch((error) => {
          this.busy = false;
          this.$emit('refetch-data');
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Failed to edit room',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
              text: error.response ? error.response.data.message : error.message,
            },
          });
        });
    },
    fetchBuildings() {
      store
        .dispatch('siteStoreModule/fetchBuildingList')
        .then((response) => {
          this.busy = false;
          this.buildingOptions = response.data.results.map((building) => ({
            label: building.name,
            value: building.id,
          }));
        })
        .catch((error) => {
          this.busy = false;
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Failed to fetch buildings',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
              text: error.response ? error.response.data.message : error.message,
            },
          });
        });
    },
  },
  mounted() {
    this.fetchBuildings();
  },
  setup(props) {
    const roomEditData = ref({});
    const busy = ref(false);

    const { statusOptions, typeOptions } = useRoom();

    return { statusOptions, typeOptions, roomEditData, busy };
  },
};
</script>

<style lang="scss">
@import '@core/scss/vue/libs/quill.scss';
</style>

<template>
  <b-modal
    ref="modalCreateRoom"
    :visible="createRoomActive"
    @hidden="closeModal"
    size="lg"
    :busy="busy"
    :title="`New Room`"
    ok-title="Create Room"
    centered
    body-class="pt-0"
    @ok="createEntity"
  >
    <validation-observer ref="refFormObserver">
      <b-row>
        <b-col cols="12" md="6" lg="6">
          <validation-provider #default="validationContext" name="Room Name">
            <b-form-group label="Room Name" label-for="room-name">
              <b-form-input
                required
                autofocus
                id="room-name"
                placeholder=""
                v-model="roomCreateData.name"
                :state="getValidationState(validationContext)"
              />
            </b-form-group>
          </validation-provider>
        </b-col>

        <b-col cols="12" md="6" lg="6">
          <b-form-group label="Room Building" label-for="room-building">
            <v-select
              v-model="roomCreateData.building"
              :reduce="(val) => val.value"
              :options="buildingOptions"
              :clearable="false"
              transition="smooth"
              input-id="room-building"
            />
          </b-form-group>
        </b-col>

        <b-col cols="12" md="6" lg="6">
          <b-form-group label="Room Floor" label-for="room-floor">
            <b-form-input id="room-floor" placeholder="Ex. B1" v-model="roomCreateData.floor" />
          </b-form-group>
        </b-col>

        <b-col cols="12" md="6" lg="6">
          <b-form-group label="Room Number" label-for="room-number">
            <b-form-input id="room-number" placeholder="Ex. 405" v-model="roomCreateData.number" />
          </b-form-group>
        </b-col>

        <b-col cols="12" md="6" lg="6">
          <b-form-group label="Room Capacity" label-for="room-capacity">
            <b-form-input id="room-capacity" placeholder="Ex. 100" v-model="roomCreateData.capacity" />
          </b-form-group>
        </b-col>

        <b-col cols="12" md="6" lg="6">
          <b-form-group label="Room Directions" label-for="room-directions">
            <b-form-input id="room-directions" placeholder="Ex. Go down the hall and turn left" v-model="roomCreateData.directions" />
          </b-form-group>
        </b-col>

        <b-col cols="12" md="6" lg="6">
          <b-form-group label="Room Avatar URL" label-for="room-avatar">
            <b-form-input id="room-avatar" placeholder="" v-model="roomCreateData.avatar" />
          </b-form-group>
        </b-col>

        <b-col cols="12" md="6" lg="6">
          <b-form-group label="Room Picture URL" label-for="room-picture">
            <b-form-input id="room-picture" placeholder="" v-model="roomCreateData.pictureUrl" />
          </b-form-group>
        </b-col>

        <b-col cols="12" md="6" lg="6">
          <b-form-group label="Status" label-for="room-status">
            <v-select
              v-model="roomCreateData.status"
              :options="statusOptions"
              :reduce="(val) => val.value"
              :clearable="false"
              required
              transition="smooth"
              input-id="room-status"
            />
          </b-form-group>
        </b-col>

        <b-col cols="12" md="6" lg="6">
          <b-form-group label="Room Type" label-for="room-type">
            <v-select
              v-model="roomCreateData.type"
              :reduce="(val) => val.value"
              :options="typeOptions"
              :clearable="false"
              transition="smooth"
              input-id="room-type"
            />
          </b-form-group>
        </b-col>
      </b-row>
    </validation-observer>
  </b-modal>
</template>

<script>
import {
  BCol,
  BFormGroup,
  BButton,
  BFormInput,
  BFormTextarea,
  BFormSelect,
  BFormSelectOption,
  BFormInvalidFeedback,
  BModal,
  BRow,
  VBModal,
} from 'bootstrap-vue';
import 'quill/dist/quill.snow.css';
import { quillEditor } from 'vue-quill-editor';
import { ValidationProvider, ValidationObserver } from 'vee-validate';
import formValidation from '@core/comp-functions/forms/form-validation';
import store from '@/store';
import siteStoreModule from '@/store/site/siteStoreModule';
import { ref } from '@vue/composition-api/dist/vue-composition-api';
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';
import useRoom from '../useRoom';
import vSelect from 'vue-select';
import states from '@/libs/data/states';

export default {
  name: 'RoomEditModal',
  components: {
    BRow,
    BCol,
    BFormGroup,
    BButton,
    BFormInput,
    BFormTextarea,
    BFormSelect,
    BFormSelectOption,
    BFormInvalidFeedback,
    BModal,
    quillEditor,
    vSelect,
    siteStoreModule,

    ValidationProvider,
    ValidationObserver,
  },
  directives: { 'b-modal': VBModal },
  props: {
    createRoomActive: {
      type: Boolean,
      required: true,
      default: false,
    },
  },
  watch: {
    '$props.createRoomActive'(val) {
      this.initModal();
    },
  },
  created() {
    this.initModal();
  },
  methods: {
    initModal() {
      this.roomCreateData = {
        name: null,
        building: null,
        directions: null,
        capacity: null,
        floor: null,
        number: null,
        avatar: null,
        pictureUrl: null,
        type: 'zoom',
        status: 'created',
      };
    },
    closeModal() {
      this.busy = false;
      this.$emit('update:create-room-active', false);
    },
    validateForm() {
      if (
        !this.roomCreateData.name ||
        !this.roomCreateData.type ||
        !this.roomCreateData.building ||
        !this.roomCreateData.floor ||
        !this.roomCreateData.capacity
      ) {
        return false;
      }
      return true;
    },
    createEntity(event) {
      this.busy = true;
      event.preventDefault();

      const pl = { ...this.roomCreateData };
      pl.capacity = parseInt(pl.capacity);

      if (this.validateForm() !== true) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Missing Required Fields',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
            text: 'Please fill out all required fields.',
          },
        });
        this.busy = false;
        return;
      }

      console.log(pl);

      store
        .dispatch('siteStoreModule/createRoom', { roomData: pl })
        .then((response) => {
          this.$emit('update:room-data', response.data);

          this.busy = false;
          this.$emit('refetch-data');
          this.closeModal();

          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Room Created!',
              icon: 'EditIcon',
              variant: 'success',
            },
          });
        })
        .catch((error) => {
          this.busy = false;
          this.$emit('refetch-data');
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Failed to create room',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
              text: error.response ? error.response.data.message : error.message,
            },
          });
        });
    },
    fetchBuildings() {
      store
        .dispatch('siteStoreModule/fetchBuildingList')
        .then((response) => {
          this.busy = false;
          this.buildingOptions = response.data.results.map((building) => ({
            label: building.name,
            value: building.id,
          }));
        })
        .catch((error) => {
          this.busy = false;
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Failed to fetch buildings',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
              text: error.response ? error.response.data.message : error.message,
            },
          });
        });
    },
  },
  data() {
    return {
      states,
      buildingOptions: [],
    };
  },
  mounted() {
    this.fetchBuildings();
  },
  setup(props) {
    const roomCreateData = ref({});
    const busy = ref(false);

    const { refFormObserver, getValidationState, resetForm } = formValidation(roomCreateData);
    const { statusOptions, typeOptions } = useRoom();

    return { statusOptions, typeOptions, roomCreateData, busy, refFormObserver, getValidationState, resetForm };
  },
};
</script>

<style lang="scss">
@import '@core/scss/vue/libs/quill.scss';

.vs__dropdown-menu {
  max-height: 250px !important;
}
</style>

import { ref, watch, computed } from '@vue/composition-api';

// Notification
import { useToast } from 'vue-toastification/composition';
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';
import store from '@/store';

export default function useRoom() {
  // Use toast
  const toast = useToast();

  const refRoomListTable = ref(null);

  // Table Handlers
  const tableColumns = [
    { key: 'name', sortable: true },
    { key: 'building', sortable: true },
    { key: 'type', sortable: true },
    { key: 'occupancy', sortable: true },
    { key: 'capacity', sortable: true },
    { key: 'floor', sortable: true },
    { key: 'actions' },
  ];
  const perPage = ref(10);
  const totalRoom = ref(0);
  const currentPage = ref(1);
  const totalPageCount = ref(0);
  const perPageOptions = [10, 25, 50, 100];
  const searchQuery = ref('');
  const sortBy = ref('id');
  const isSortDirDesc = ref(true);

  const typeFilter = ref(null);
  const statusFilter = ref(null);

  const dataMeta = computed(() => {
    const localItemsCount = refRoomListTable.value ? refRoomListTable.value.localItems.length : 0;
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalRoom.value,
    };
  });

  const refetchData = () => {
    refRoomListTable.value.refresh();
  };

  watch([currentPage, perPage, searchQuery, typeFilter, statusFilter], () => {
    refetchData();
  });

  const fetchRoomList = (ctx, callback) => {
    const sbv = sortBy.value === 'id' ? '_id' : sortBy.value;
    const sb = `${sbv}:${isSortDirDesc.value ? 'desc' : 'asc'}`;

    const pl = {
      search: searchQuery.value,
      limit: perPage.value,
      page: currentPage.value,
      sortBy: sb,
      populate: 'building',
    };

    if (typeFilter.value) {
      pl.type = typeFilter.value;
    }
    if (statusFilter.value) {
      pl.status = statusFilter.value;
    }

    store
      .dispatch('siteStoreModule/fetchRoomList', pl)
      .then((response) => {
        const { results, totalResults, totalPages } = response.data;

        callback(results);
        totalRoom.value = totalResults;
        totalPageCount.value = totalPages;
      })
      .catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: 'Error fetching rooms',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        });
      });
  };

  // archived, internal, public

  // *===============================================---*
  // *--------- UI ---------------------------------------*
  // *===============================================---*

  const resolveRoomTypeVariant = (type) => {
    if (type === 'physical') return 'light-warning';
    if (type === 'zoom') return 'light-info';
    if (type === 'teams') return 'light-success';
    if (type === 'webex') return 'light-primary';
    if (type === 'discord') return 'light-secondary';
    return '';
  };

  const resolveRoomStatusVariant = (status) => {
    if (status === 'created') return 'light-secondary';
    if (status === 'active') return 'light-success';
    if (status === 'archived') return 'light-danger';
    return 'info';
  };

  const statusOptions = [
    { label: 'Created', value: 'created' },
    { label: 'Active', value: 'active' },
    { label: 'Archived', value: 'archived' },
  ];

  const typeOptions = [
    { label: 'Physical', value: 'physical' },
    { label: 'Zoom', value: 'zoom' },
    { label: 'Teams', value: 'teams' },
    { label: 'Webex', value: 'webex' },
    { label: 'Discord', value: 'discord' },
  ];

  return {
    fetchRoomList,
    tableColumns,
    perPage,
    currentPage,
    totalPageCount,
    totalRoom,
    dataMeta,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    refRoomListTable,

    resolveRoomTypeVariant,
    resolveRoomStatusVariant,
    refetchData,

    typeOptions,
    statusOptions,

    // Extra Filters
    typeFilter,
    statusFilter,
  };
}
